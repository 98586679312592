import { makeStyles } from "@material-ui/core";
import moment from "moment";

import { Card } from "../components/card/Card";
import { LegacyCardContent } from "../components/card/CardContent";
import { CustomerDocument } from "./customer.model";
import { Address } from "../address.model";
import { CardAlert } from "../components/card/CardAlert";
import { CardHeader } from "../components/card/CardHeader";

const useStyles = makeStyles({
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },

        "& > span": {
            fontSize: "16px",
            fontWeight: 400,
            color: "#4A4A4A",

            "& > a": {
                fontSize: "16px",
                fontWeight: 500,
                color: "#297A1D",
                textDecoration: "none",
        
                "&:hover": {
                    color: "#1C5B13",
                    textDecoration: "underline",
                },
            },
        },
    },
});

interface CustomerCardProps {
    customer: CustomerDocument;
    button?: JSX.Element;
}

export const CustomerDetailCard = (props: CustomerCardProps) => {
    const classes = useStyles();

    const { customer, button } = props;

    return (
        <Card>

            <CardHeader button={button}>{customer.name}</CardHeader>

            {customer.status === "deleted" ? (
                <CardAlert>
                    <p>{formatDaysUntilDeletion(customer.deletedDate)}</p>
                </CardAlert>
            ) : null}

            <LegacyCardContent>

                <div className={classes.group}>
                    <label>Id</label>
                    <span>{customer.id}</span>
                </div>

                <div className={classes.group}>
                    <label>Adresse</label>
                    <span>
                        <a href={gpsHref(customer.address)} target="_blank" rel="noreferrer">
                            {customer.address.street}<br />
                            {customer.address.zipCode}
                        </a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Email</label>
                    <span>
                        <a href={`mailto:${customer.email}`}>{customer.email}</a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Telefon</label>
                    <span>
                        <a href={`tel:${customer.phoneNumber}`}>{customer.phoneNumber}</a>
                    </span>
                </div>

            </LegacyCardContent>
        </Card>
    );
};

function gpsHref(address: Address): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}

function daysUntilDeletion(deletionDate: string): number {
    const deletionReadyDate = moment(deletionDate).add(30, "days");
    const timeOfDeletion = deletionReadyDate.add(1, "day").startOf("day");

    const difference = -moment().diff(timeOfDeletion);

    return Math.floor(moment.duration(difference).asDays());
}

function formatDaysUntilDeletion(deletionDate: string): string {
    const days = daysUntilDeletion(deletionDate);
    if ( days === 0 ) return "Slettes ved midnat";
    if ( days === 1 ) return "Slettes om 1 dag";
    
    return `Slettes om ${days} dage`;
}
