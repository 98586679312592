import { Container } from "@material-ui/core";
import CreateGardeningTaskIcon from "@material-ui/icons/AddCircleOutline";
import CreateTaskIcon from "@material-ui/icons/AddAlertOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import ReactivateIcon from "@material-ui/icons/PlayCircleOutline";

import { ContentSection } from "../layout/ContentSection";
import { TitleHeader } from "../components/TitleHeader";
import { BackButton } from "../components/BackButton";
import { MenuButton } from "../components/menu/MenuButton";
import { MenuItem } from "../components/menu/MenuItem";
import { CustomerDetailCard } from "./CustomerDetailCard";
import { ActivitySection } from "../comment/ActivitySection";
import { CustomerCommentList } from "../comment/CustomerCommentList";
import { MenuDivider } from "../components/menu/MenuDivider";
import { UpdateCustomerDialogViewModel, useUpdateCustomerDialog } from "./update-customer/UpdateCustomerDialog";
import { useCallback, useMemo } from "react";
import { ManageAccountsIcon } from "../components/icon/ManageAccountsIcon";
import { ReactivateCustomerDialogViewModel, useReactivateCustomerDialog } from "./reactivate-customer/ReactivateCustomerDialog";
import { useDeleteCustomerDialog } from "./DeleteCustomerDialog";
import { AdministratorActionData, DeleteCustomerReason } from "../workflow/administrator-action.hook";
import { CustomerDocument } from "./customer.model";
import { DineroContactDocument } from "../finance/billing/pending-approval/dinero-contact.model";
import { CommentDocument } from "../comment/comment.model";
import { CreateTaskDialogViewModel, useCreateTaskDialog } from "../task/task-dialog/CreateTaskDialog";
import { CreateGardeningTaskFormIds, useCreateGardeningTaskDialog } from "./create-gardening-task/CreateGardeningTaskDialog";
import { FormProps } from "../components/form-item/framework/react/FormProvider";
import { FormItem } from "../components/form-item/framework/core/form-item.model";
import { MultiChoiceFormItem, SingleChoiceFormItem, TextAreaFormItem } from "../components/form-item/client/components";
import { AddressPickerFormItem } from "../components/form-item/client/form-item/AddressPickerFormItemComponent";
import { GardenerPickerFormItem } from "../components/form-item/client/form-item/GardenerPickerFormItemComponent";
import { Section, SectionHeader } from "../components/section";
import { GardeningTaskCustomerList } from "../gardening-task/list/GardeningTaskCustomerList";
import { GardeningTaskDocument, Skill, HourInterval, TaskFrequency, TaskTools } from "../gardening-task/gardening-task.model";
import { GardenerDocument } from "../gardener/gardener.model";

export interface CustomerDetailPageProps {
    customer: CustomerDocument;
    contact: DineroContactDocument | null;
    gardeningTasks: GardeningTaskDocument[];
    gardeners: GardenerDocument[];
    comments: CommentDocument[];
    addComment: (text: string) => void;
    administratorAction: (data: AdministratorActionData | string) => void;
    createTaskAction: (viewModel: CreateTaskDialogViewModel) => void;
    reactivateCustomerAction: (viewModel: ReactivateCustomerDialogViewModel) => void;
    updateCustomerAction: (viewModel: UpdateCustomerDialogViewModel, idempotencyKey: string) => void;
    goBack: () => void;
    onSelectGardeningTask: (gardeningTask: GardeningTaskDocument) => void;
}

export const CustomerDetailPage = (props: CustomerDetailPageProps) => {
    const { customer, contact, gardeners, gardeningTasks, comments, addComment, administratorAction, createTaskAction, reactivateCustomerAction, updateCustomerAction, goBack, onSelectGardeningTask } = props;

    const createGardeningTaskAdapter = useCallback<FormProps<FormItem>["onSubmit"]>((controller, item) => {
        const externalAddressId = (controller.getItem(CreateGardeningTaskFormIds.AddressPickerAddress, item) as AddressPickerFormItem).value!.externalId;
        
        const requiredSkills: Skill[] = (() => {
            const selection = (controller.getItem(CreateGardeningTaskFormIds.MultiChoiceRequiredSkills, item) as MultiChoiceFormItem<any[], any>).selectedChoiceIndexes as Array<0 | 1 | 2 | 3 | 4 | 5>;
            
            return selection.map((index): Skill => {
                switch ( index ) {
                    case 0: return "lawn-mowing";
                    case 1: return "weed-control";
                    case 2: return "hedge-trimming";
                    case 3: return "pruning-of-trees-and-shrubs";
                    case 4: return "disposal-of-garden-waste";
                    case 5: return "other-garden-services";
                }

                return undefined as any;
            });
        })();

        const frequency: TaskFrequency = ((): TaskFrequency => {
            const selection = (controller.getItem(CreateGardeningTaskFormIds.SingleChoiceFrequency, item) as SingleChoiceFormItem<any[]>).selectedChoiceIndex as 0 | 1 | 2 | 3;

            switch ( selection ) {
                case 0: return "once";
                case 1: return "once-every-week";
                case 2: return "once-every-second-week";
                case 3: return "once-every-month";
            }
        })();

        const hourInterval: HourInterval = ((): HourInterval => {
            const selection = (controller.getItem(CreateGardeningTaskFormIds.SingleChoiceDuration, item) as SingleChoiceFormItem<any[]>).selectedChoiceIndex as 0 | 1 | 2;

            switch ( selection ) {
                case 0: return "1-2 hours";
                case 1: return "3-5 hours";
                case 2: return "more-than-6-hours";
            }
        })();

        const tools: TaskTools = ((): TaskTools => {
            const selection = (controller.getItem(CreateGardeningTaskFormIds.SingleChoiceTools, item) as SingleChoiceFormItem<any[]>).selectedChoiceIndex as 0 | 1;

            switch ( selection ) {
                case 0: return "customer-tools";
                case 1: return "helper-tools";
            }
        })();

        const description = (controller.getItem(CreateGardeningTaskFormIds.TextAreaDescription, item) as TextAreaFormItem).value;

        const shouldFindGardenerAutomatically = (controller.getItem(CreateGardeningTaskFormIds.SingleChoiceFindGardenerStrategy, item) as SingleChoiceFormItem<any[]>).selectedChoiceIndex === 0;
        if ( shouldFindGardenerAutomatically ) {
            return administratorAction({
                action: "create gardening task",
                customerId: customer.id,
                externalAddressId,
                requiredSkills,
                frequency,
                hourInterval,
                tools,
                description,
                findGardenerStrategy: {
                    type: "look for gardener",
                },
            });
        }

        const gardenerId = (controller.getItem(CreateGardeningTaskFormIds.GardenerPickerGardener, item) as GardenerPickerFormItem).gardener!.id;
        const reason = (controller.getItem(CreateGardeningTaskFormIds.TextAreaReason, item) as TextAreaFormItem).value;

        return administratorAction({
            action: "create gardening task",
            customerId: customer.id,
            externalAddressId,
            requiredSkills,
            frequency,
            hourInterval,
            tools,
            description,
            findGardenerStrategy: {
                type: "specific gardener",
                gardenerId,
                reason,
            },
        });
    }, [administratorAction, customer]);
    const openCreateGardeningTaskDialog = useCreateGardeningTaskDialog(customer, createGardeningTaskAdapter);

    const openReactivateCustomerDialog = useReactivateCustomerDialog(reactivateCustomerAction);
    const openCreateTaskDialog = useCreateTaskDialog(createTaskAction);

    const deleteCustomerAdapter = useCallback((reason: DeleteCustomerReason, explanation: string) => {
        administratorAction({
            action: "delete customer",
            customerId: customer.id,
            reason,
            explanation,
        })
    }, [administratorAction, customer]);
    const openDeleteCustomerDialog = useDeleteCustomerDialog(customer, deleteCustomerAdapter);

    const initialUpdateCustomerDialogViewModel = useMemo<UpdateCustomerDialogViewModel | undefined>(() => {
        if ( !contact ) return undefined;

        return {
            name: customer.name,
            nameDirty: false,
            email: customer.email,
            emailDirty: false,
            phoneNumber: customer.phoneNumber,
            phoneNumberDirty: false,
            addressSearch: {
                searchTerm: `${customer.address.street}, ${customer.address.zipCode}`,
                address: undefined,
            },
            addressSearchDirty: false,
            billing: {
                name: contact.name,
                nameDirty: false,
                email: contact.email ?? "",
                emailDirty: false,
                phoneNumber: contact.phoneNumber,
                phoneNumberDirty: false,
                street: contact.address.street,
                streetDirty: false,
                zipCode: contact.address.zipCode,
                zipCodeDirty: false,
                type: !contact.cvr ? "person" : "company",
                typeDirty: false,
                company: {
                    cvr: contact.cvr ?? "",
                    cvrDirty: false,
                    att: contact.att ?? "",
                    attDirty: false,
                    ean: contact.ean ?? "",
                    eanDirty: false,
                },
            },
        };
    }, [customer, contact]);
    const openUpdateCustomerDialog = useUpdateCustomerDialog(initialUpdateCustomerDialogViewModel, updateCustomerAction);

    const menuButton = customer.status !== "deleted" ? (
        <MenuButton>
            <MenuItem icon={<CreateGardeningTaskIcon />} onClick={openCreateGardeningTaskDialog}>Opret opgave</MenuItem>
            <MenuItem icon={<CreateTaskIcon />} onClick={openCreateTaskDialog}>Planlæg diverse</MenuItem>
            <MenuItem icon={<ManageAccountsIcon />} onClick={openUpdateCustomerDialog}>Ændr oplysninger</MenuItem>
            <MenuDivider />
            <MenuItem icon={<DeleteIcon />} onClick={openDeleteCustomerDialog}>Slet</MenuItem>
        </MenuButton>
    ) : (
        <MenuButton>
            <MenuItem icon={<ReactivateIcon />} onClick={openReactivateCustomerDialog}>Genaktiver</MenuItem>
        </MenuButton>
    );

    const backButton = <BackButton onClick={goBack} />;

    return (
        <>
            <TitleHeader button={backButton}>{customer.name}</TitleHeader>
            
            <ContentSection>
                <Container maxWidth="sm" disableGutters>

                    <Section>
                        <CustomerDetailCard customer={customer} button={menuButton} />
                    </Section>
                    
                    <Section>
                        <SectionHeader>Opgaver</SectionHeader>
                        <GardeningTaskCustomerList gardeningTasks={gardeningTasks} gardeners={gardeners} onSelect={onSelectGardeningTask} />
                    </Section>

                    <ActivitySection onSave={addComment}>
                        <CustomerCommentList comments={comments} />
                    </ActivitySection>

                </Container>
            </ContentSection>
        </>
    );
};
