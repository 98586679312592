import { Hit } from "@algolia/client-search";
import { IconButton, makeStyles } from "@material-ui/core";
import ProfileIcon from "@material-ui/icons/ArrowForwardOutlined";
import sanitizeHtml from "sanitize-html";

import { Card } from "../../../components/card/Card";
import { LegacyCardContent } from "../../../components/card/CardContent";
import { Address } from "../../../address.model";
import { GardenerDocument } from "../../gardener.model";
import { CardHeader } from "../../../components/card/CardHeader";

const useStyles = makeStyles({
    root: {
        "& em": {
            backgroundColor: "#A259FF",
            color: "#FFFFFF",
            fontStyle: "normal",
            lineHeight: "125%",
        },
    },
    group: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "24px",

        "&:last-child": {
            marginBottom: 0,
        },

        "& > label": {
            fontSize: "14px",
            fontWeight: 500,
            color: "#1C5B13",
            marginBottom: "4px",
        },
    },
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",

        "& > a": {
            fontSize: "16px",
            fontWeight: 500,
            color: "#297A1D",
            textDecoration: "none",
    
            "&:hover": {
                color: "#1C5B13",
                textDecoration: "underline",
            },
        },
    },
    icon: {
        fontSize: "24px",
        color: "#297A1D",
    },
});

interface GardenerSearchResultCardProps {
    gardener: Hit<GardenerDocument>;
    goToProfile: () => void;
}

export const GardenerSearchResultCard = (props: GardenerSearchResultCardProps) => {
    const classes = useStyles();

    const { gardener, goToProfile } = props;

    const menuButton = (
        <IconButton aria-label="go to profile" onClick={goToProfile}>
            <ProfileIcon className={classes.icon} />
        </IconButton>
    );

    return (
        <Card className={classes.root}>

            <CardHeader button={menuButton}>
                <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(gardener._highlightResult?.name?.value ?? gardener.name) }} />
            </CardHeader>

            <LegacyCardContent>

                <div className={classes.group}>
                    <label>Adresse</label>
                    <span className={classes.text}>
                        <a href={gpsHref(gardener.address)} target="_blank" rel="noreferrer">
                            <span dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(gardener._highlightResult?.address?.street?.value ?? gardener.address.street)
                            }} /><br />
                            <span dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(gardener._highlightResult?.address?.zipCode?.value ?? gardener.address.zipCode)
                            }} />
                        </a>
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Email</label>
                    <span className={classes.text}>
                        <a href={`mailto:${gardener.email}`} dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(gardener._highlightResult?.email?.value ?? gardener.email)
                        }} />
                    </span>
                </div>

                <div className={classes.group}>
                    <label>Telefon</label>
                    <span className={classes.text}>
                        <a href={`tel:${gardener.phoneNumber}`} dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(gardener._highlightResult?.phoneNumber?.value ?? gardener.phoneNumber)
                        }} />
                    </span>
                </div>

            </LegacyCardContent>
        </Card>
    );
};

function gpsHref(address: Address): string {
    const query = `${address.street}, ${address.zipCode}`;

    return encodeURI(`https://maps.google.com/?q=${query}`);
}
