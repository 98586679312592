import { useMemo } from "react";

import { PageFormItem, GroupFormItem, InfoFormItem } from "../../components/form-item/client/components";
import { FormItem } from "../../components/form-item/framework/core/form-item.model";
import { NoticeFormItem } from "../../components/form-item/client/form-item/NoticeFormItemComponent";

export type ExplainConceptForm<NextPage extends FormItem> = ExplainConceptPage<NextPage>;

type ExplainConceptPage<NextPage extends FormItem> =
    PageFormItem<
        GroupFormItem<[
            InfoFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NoticeFormItem,
            NextPage,
        ]>
    >

export const useExplainConceptForm = <T extends FormItem>(nextPage: T): ExplainConceptForm<T> => {
    return useMemo(() => {
    
        const page: ExplainConceptPage<T> = {
            type: "page",
            title: "Fortæl om Go Go Garden",
            item: {
                type: "group",
                render: "all at once",
                items: [
                    {
                        type: "info",
                        render: "default",
                        text: "Fortæl ansøgeren om Go Go Garden ud fra nedenstående punkter. Vælg “Næste”, når du er færdig.",
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Konceptet</strong><br />
                            <br />
                            Du kommer til at bruge det system, som vi har udviklet til vores havemænd. Herinde kan du finde en oversigt over dine opgaver.<br />
                            <br />
                            Det kan være nye opgaver, du får tilbudt, eller det kan være opgaver på at registrere tid, for at nævne et par eksempler.<br />
                            <br />
                            Alle opgaverne har en tidsfrist, som de skal besvares inden for, og du vil få en påmindelse på email og sms, når du har en aktuel opgave.
                            Vi forventer selvfølgelig, at du besvarer dem inden for tidsfristen, både for at kunderne får hurtigt svar og for at vores samarbejde fungerer bedst muligt.<br />
                            <br />
                            Når du besvarer en opgave bliver der åbnet en dialog, som beder dig om at besvare nogle spørgsmål og ud fra dine svar foretager systemet så den rigtige handling.
                            Det er vigtigt at være opmærksom på hvad der står i den dialog, så du ikke kommer til f.eks. at afvise en kunde ved en fejl.
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Indstilling af din profil</strong><br />
                            <br />
                            På din profil kan du ændre dine indstillinger, som har en indflydelse på hvilke opgaver, du bliver tilbudt.<br />
                            <br />
                            Jo flere opgaver og redskaber du slår til herinde og jo længere du vil køre, jo flere opgaver kan vi tilbyde dig.<br />
                            <br />
                            Omvendt vil du få tilbudt færre opgaver, hvis du slår meget få ting til og sætter din radius lavt.
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Hvordan vi tilbyder dig opgaver</strong><br />
                            <br />
                            Du må altid gerne sige nej tak til en opgave, hvis den ikke er noget for dig. Vi bedømmer kun vores samarbejde på de opgaver, du siger ja tak til.<br />
                            <br />
                            Til at starte med giver jeg dig mulighed for at sige ja tak til 3 opgaver i alt. Hver gang du løser en opgave, vil du få mulighed for at sige ja tak til endnu flere opgaver, omvendt vil du få færre opgaver, hvis ikke det lykkes dig at løse dem.<br />
                            <br />
                            Vi forventer ikke, at 100% af alle opgaver lykkes, fordi meget også afhænger af kunden, men jo bedre du gør det, jo flere opgaver vil du få tilbudt.
                        `,
                    },
                    {
                        type: "notice",
                        render: "info",
                        html: `
                            <strong>Bed om hjælp</strong><br />
                            <br />
                            Hvis du får spørgsmål eller bliver i tvivl om noget, er der en knap inde på din side, som hedder “Bed om hjælp”. Den kan du bruge til at kontakte os, og så svarer vi dig så hurtigt vi kan.<br />
                            <br />
                            Vi vil helst kontaktes vha. den kanal, frem for sms, opkald eller emails.
                        `,
                    },
                    nextPage,
                ],
            },
        };
        
        return page;
    }, [nextPage]);
}
