import { makeStyles } from "@material-ui/core";
import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { UnorderedList } from "../../components/unordered-list/UnorderedList";
import { CustomerUpdatedCommentDocument, GardenerUpdatedCommentDocument, OrderUpdatedCommentDocument } from "../comment.model";

const useStyles = makeStyles({
    label: {
        marginBottom: "4px",
    },
})

interface UpdatedCommentProps {
    context: "customer" | "gardener";
    comment: GardenerUpdatedCommentDocument | CustomerUpdatedCommentDocument | OrderUpdatedCommentDocument;
}

export const UpdatedComment = (props: UpdatedCommentProps) => {
    const classes = useStyles();
    const { comment } = props;

    const title = (() => {
        switch ( comment.type ) {
            case "gardener-updated": return "Havemandens oplysninger blev ændret";
            case "customer-updated": return "Kundens oplysninger blev ændret";
            case "order-updated": return "Kundens bestilling blev ændret";
        }
    })();

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title={title} author={comment.administratorName}>
            <CommentBody>
                <p className={classes.label}>{title}:</p>

                <UnorderedList>
                    {comment.changes.map((change, index) => (
                        <li key={index}>{change}</li>
                    ))}
                </UnorderedList>

            </CommentBody>
        </Comment>
    );
};
