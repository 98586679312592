import { Comment } from "../../components/comment/Comment";
import { CommentBody } from "../../components/comment/CommentBody";
import { GardenerPausedCommentDocument } from "../comment.model";

interface GardenerPausedCommentProps {
    comment: GardenerPausedCommentDocument;
}

export const GardenerPausedComment = (props: GardenerPausedCommentProps) => {
    const { comment } = props;

    return (
        <Comment date={comment.creationDate.toDate().toISOString()} title="Havemand er sat på pause" author={comment.administratorName}>
            <CommentBody>
                Årsag:<br />
                {comment.reason}
            </CommentBody>
        </Comment>
    );
};
